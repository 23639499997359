import {
  Card, Layout,
} from 'antd';
import React from 'react';
import logo from './yerevanhelp-logo.png';
import instagram from './instagram.webp';
import facebook from './facebook.webp';
import tiktok from './tiktok.webp';
import telegram from './telegram.png';

const HomePage: React.FC = () => (
  <Layout style={{
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  }}
  >
    <img src={logo} style={{ width: 300 }} alt="yerevanhelp-logo" />
    <p style={{ textAlign: 'center', fontSize: 24, marginTop: 50 }}>Join us in social media #yerevanhelp</p>
    <Card style={{
      marginTop: 30,
    }}
    >
      <a target="_blank" href="https://www.instagram.com/yerevanhelp/" rel="noreferrer">
        <img src={instagram} style={{ width: 50, marginLeft: 20 }} alt="instagram" />
      </a>
      <a target="_blank" href="https://t.me/yerevanhelp_community" rel="noreferrer">
        <img src={telegram} style={{ width: 50, marginLeft: 20 }} alt="telegram" />
      </a>
      <a target="_blank" href="https://www.tiktok.com/@yerevanhelp" rel="noreferrer">
        <img src={tiktok} style={{ width: 50, marginLeft: 20 }} alt="tiktok" />
      </a>
      <a target="_blank" href="https://www.facebook.com/people/Yerevan-help/100084702349910/" rel="noreferrer">
        <img src={facebook} style={{ width: 50, marginLeft: 20 }} alt="facebook" />
      </a>
    </Card>
  </Layout>
);

export { HomePage };
