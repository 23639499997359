import React from 'react';
import { HomePage } from './pages/homePage';

function App() {
  return (
    <HomePage />
  );
}

export default App;
